import React, { useContext, useEffect } from 'react';
// import clsx from 'clsx';
import './_layout.scss';

// AUTH0
import { useAuth0 } from '@auth0/auth0-react';

// LOGO
import LogoText from '../../assets/title-white.png';
import kiwibotIcon from '../../assets/kiwibot-icon.png';

// Sweet Alert
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// STORE/HOOKS
import AppContext from '../../utils/context/AppContext';
import useConnected from '../../hooks/useConnected';
import axios from 'axios';

const Layout = ({ children }) => {
  const MySwal = withReactContent(Swal);
  const { updateUser } = useContext(AppContext);
  const { isConnected } = useConnected();
  const { user, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      getDataUser();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (!isConnected) {
    MySwal.fire({
      title: "You're offline; check your connection.",
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: false,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: 'OK',
      confirmButtonColor: '#0082F5',
    });
  }

  const getDataUser = async () => {
    const accessToken = await getAccessTokenSilently();
    const { data } = await axios.get('https://kiwibot.us.auth0.com/userinfo', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    updateUser(data);
  };

  const initialsName = `${
    user.given_name ? user.given_name.charAt(0).toUpperCase() : 'K'
  }${user.family_name ? user.family_name.charAt(0).toUpperCase() : 'B'}`;

  return (
    isAuthenticated && (
      <>
        <header className='header'>
          <div className='header__topbar'>
            <div className='logo'>
              <img src={LogoText} alt='Kiwibot Logo' />
              <div className='logo__text'>
                <span>Stores Platform</span>
              </div>
            </div>
            <div className='logout'>
              <span
                onClick={() => logout({ returnTo: window.location.origin })}>
                Logout
              </span>
            </div>
          </div>

          <div className='header__hero'>
            <div className='header__hero__img'>
              <img src={kiwibotIcon} alt='Kiwibot Icon' />
            </div>
            <div className='header__hero__container'>
              <p>Store</p>
              <div className='card_container'>
                <div className='circle-icon'>{initialsName}</div>
                <p>{user.name}</p>
              </div>
            </div>
          </div>
        </header>
        <main className='principal-container'>{children}</main>
      </>
    )
  );
};

export default Layout;
