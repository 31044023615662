import React, { useState, useMemo, useContext } from "react";

import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faListAlt,
    faHistory,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

// APP CONTEXT
import AppContext from "../utils/context/AppContext";

// SCSS
import "./_ordersPages.scss";
import DialogSimple from "../components/DialogSimple/DialogSimple";

const atheneaLmmUrl = process.env.REACT_APP_ATHENEA_LMM;

const CanceledOrders = () => {
    const { state } = useContext(AppContext);
    const { user } = state;

    const fetchDeliveries = async () => {
        const res = await axios.post(
            `${atheneaLmmUrl}/deliveries/search/requesters?activity=canceled`,
            {
                requester_ids: user?.metadataTwo?.requester_id || [],
            }
        );
        return res.data.data;
    };

    const { status, data } = useQuery("canceledDeliveries", fetchDeliveries, {
        refetchInterval: 1000,
        refetchOnWindowFocus: true,
        staleTime: 1000,
        cacheTime: 0,
        keepPreviousData: false,
        enabled: user ? true : false,
    });

    const [externalIdAndClientNameFilter, setExternalIdAndClientNameFilter] =
        useState("");

    const canceledOrders = useMemo(() => {
        if (!data) return [];
        if (externalIdAndClientNameFilter.length > 0) {
            return data.deliveries.filter((delivery) =>
                delivery.dropoff.name
                    ? delivery.dropoff.name
                          .toLowerCase()
                          .includes(externalIdAndClientNameFilter.toLowerCase())
                    : null || delivery.external_id
                    ? delivery.external_id
                          .toLowerCase()
                          .includes(externalIdAndClientNameFilter.toLowerCase())
                    : null
            );
        }
        return data ?? [];
    }, [data, externalIdAndClientNameFilter]);

    return (
        <>
            <h2 className="typeorders_title">Canceled Orders</h2>
            <div className="orders_container">
                <div className="group-nav-top">
                    <nav className="orders_container__nav">
                        <ul>
                            <li>
                                <Link to="/">
                                    <FontAwesomeIcon
                                        style={{ marginRight: "3px" }}
                                        icon={faListAlt}
                                        size="1x"
                                        fixedWidth
                                    />
                                    Active Orders
                                </Link>
                            </li>
                            <li>
                                <Link to="/history">
                                    <FontAwesomeIcon
                                        style={{ marginRight: "3px" }}
                                        icon={faHistory}
                                        size="1x"
                                        fixedWidth
                                    />
                                    Completed Orders
                                </Link>
                            </li>
                            <li>
                                <Link className="active" to="/canceled">
                                    <FontAwesomeIcon
                                        style={{ marginRight: "3px" }}
                                        icon={faTimesCircle}
                                        size="1x"
                                        fixedWidth
                                    />
                                    Canceled Orders
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <form
                        className="orders_container__filters"
                        onSubmit={(e) => e.preventDefault()}
                        noValidate
                    >
                        <input
                            className="filter-input"
                            type="text"
                            id="filter-externalid-clientname"
                            placeholder="Filter by external id or client id"
                            onChange={(e) => {
                                setExternalIdAndClientNameFilter(
                                    e.target.value
                                );
                            }}
                        />
                    </form>
                </div>
                <table className="orders_container__table">
                    <thead>
                        <tr className="table-header">
                            <th>ID</th>
                            <th>Location Name</th>
                            <th>Estimated at pickup</th>
                            <th>Estimated delivery time</th>
                            <th>Client name</th>
                            <th>Kiwibot</th>
                            <th>Status</th>
                            <th>Details Order</th>
                        </tr>
                    </thead>
                    <tbody>
                        {status === "success" && (
                            <>
                                {canceledOrders.map((delivery) => (
                                    <tr
                                        className="table-content"
                                        key={delivery.id}
                                    >
                                        <td>{delivery.external_id ?? "N/A"}</td>
                                        <td>
                                            {delivery.pickup.location_name ??
                                                "N/A"}
                                        </td>
                                        <td>
                                            {new Date(
                                                delivery.estimated_pickup_at
                                            ).toLocaleString()}
                                        </td>
                                        <td>
                                            {new Date(
                                                delivery.estimated_delivery_at
                                            ).toLocaleString()}
                                        </td>
                                        <td>
                                            {delivery.dropoff.name ?? "N/A"}
                                        </td>
                                        <td>
                                            {delivery.worker_id
                                                ? delivery.worker_id
                                                : "No available yet"}
                                        </td>
                                        <td>{delivery.status}</td>
                                        <td>
                                            <DialogSimple
                                                dialogTitle="Details Order"
                                                buttonText="View details"
                                                delivery={delivery}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default CanceledOrders;
