import React from 'react';

import './_loading.scss';

const Loading = () => {
  return (
    <div className='loading-container'>
      <div className='circle'></div>
    </div>
  );
};

export default Loading;
