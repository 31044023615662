import { useEffect, useState } from 'react';

const useConnected = () => {
  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    window.addEventListener('offline', () => {
      setIsConnected(false);
    });
    window.addEventListener('online', () => {
      setIsConnected(true);
    });

    return () => {
      window.removeEventListener('offline', () => {
        setIsConnected(false);
      });
      window.removeEventListener('online', () => {
        setIsConnected(true);
      });
    };
  });

  return { isConnected };
};

export default useConnected;
