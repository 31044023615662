import React from "react";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import statusService from "../../utils/statusService";

import "./_DialogActions.scss";

const DialogActions = ({
    buttonText = "Button Modal",
    dialogTitle = "Title",
    delivery,
}) => {
    const MySwal = withReactContent(Swal);

    const openDoor = async () => {
        const infoBot = await statusService.openDoor({
            requesterId: delivery.requester_id,
            deliveryId: delivery.id,
        });
        if (infoBot.response) {
            if (infoBot.response.status >= 300) {
                MySwal.fire({
                    text: `${infoBot.response.data.detail.message.toString()}`,
                    icon: "error",
                    timer: 2000,
                    showConfirmButton: false,
                });
            }
        } else {
            MySwal.fire({
                title: "Door openened! Great!",
                icon: "success",
                timer: 2000,
                showConfirmButton: false,
            });
        }
    };

    const closeDoor = async () => {
        const infoBot = await statusService.closeDoor({
            requesterId: delivery.requester_id,
            deliveryId: delivery.id,
        });

        if (infoBot.response) {
            if (infoBot.response.status >= 300) {
                MySwal.fire({
                    text: `${infoBot.response.data.detail.message.toString()}`,
                    icon: "error",
                    timer: 2000,
                    showConfirmButton: false,
                });
            }
        } else {
            MySwal.fire({
                title: "Door closed! Great!",
                icon: "success",
                timer: 2000,
                showConfirmButton: false,
            });
            await statusService.markAsLoaded({
                deliveryId: delivery.id,
                zoneId: delivery.zone_id,
            });
        }
    };

    // const cancelOrder = async () => {
    //     const order = await statusService.cancelOrder({
    //         requesterId: delivery.requester_id,
    //         deliveryId: delivery.id,
    //     });

    //     if (order.response) {
    //         if (order.response.status >= 300) {
    //             MySwal.fire({
    //                 title: "Oh no! Something is not right",
    //                 icon: "error",
    //                 timer: 2000,
    //                 showConfirmButton: false,
    //             });
    //         }
    //     } else {
    //         MySwal.fire({
    //             title: "Order successfully canceled",
    //             icon: "success",
    //             timer: 2000,
    //             showConfirmButton: false,
    //         });
    //     }
    // };

    const handleOpenDoor = () => {
        // if (
        //   delivery.manifest.items.filter((item) => item.checked === true).length ===
        //   delivery.manifest.items.length
        // ) {
        MySwal.fire({
            title: "Are you in front of the assigned Kiwibot?",
            showConfirmButton: false,
            footer: <ModalGroupOptions />,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                openDoor();
            } else if (result.isDenied) {
                MySwal.fire({
                    title: "Ok, no problem ;)",
                    icon: "info",
                    timer: 2000,
                    showConfirmButton: false,
                });
            }
        });
        // } else {
        //   MySwal.fire({
        //     title: 'No items checked',
        //     text: "You can't open door if you don't have all items checked",
        //     icon: 'error',
        //     timer: 5000,
        //     showConfirmButton: false,
        //   });
        // }
    };

    const handleCloseDoor = () => {
        // if (
        //   delivery.manifest.items.filter((item) => item.checked === true).length ===
        //   delivery.manifest.items.length
        // ) {
        MySwal.fire({
            title: "Is the delivery order loaded?",
            showConfirmButton: false,
            footer: <ModalGroupOptions />,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                closeDoor();
            } else if (result.isDenied) {
                MySwal.fire({
                    title: "Ok, no problem ;)",
                    icon: "info",
                    timer: 2000,
                    showConfirmButton: false,
                });
            }
        });
        // } else {
        //   MySwal.fire({
        //     title: 'No items checked',
        //     text: "You can't close door if you don't have all items checked",
        //     icon: 'error',
        //     timer: 5000,
        //     showConfirmButton: false,
        //   });
        // }
    };

    // const handleCancelOrder = () => {
    //     MySwal.fire({
    //         title: "Do you want to cancel order?",
    //         showConfirmButton: false,
    //         footer: <ModalGroupOptions />,
    //     }).then((result) => {
    //         /* Read more about isConfirmed, isDenied below */
    //         if (result.isConfirmed) {
    //             cancelOrder();
    //         } else if (result.isDenied) {
    //             MySwal.fire({
    //                 title: "Ok, no problem ;)",
    //                 icon: "info",
    //                 timer: 2000,
    //                 showConfirmButton: false,
    //             });
    //         }
    //     });
    // };

    const ModalContainer = () => {
        return (
            <div className="modal-container">
                <div className="description">
                    <span className="description__title">Order ID:</span>
                    <p className="description__text">
                        {delivery.external_id ?? "N/A"}
                    </p>
                </div>
                <div className="description">
                    <span className="description__title">Description:</span>
                    <p className="description__text">
                        {delivery.manifest.description ?? "N/A"}
                    </p>
                </div>

                <div style={{ marginBottom: "1rem" }} className="description">
                    <span className="description__title">
                        Please confirm the order items:
                    </span>
                </div>

                {delivery.manifest.items.length > 0 ? (
                    <>
                        {delivery.manifest.items.map((item, index) => (
                            <label
                                key={item.name + "-3742893"}
                                id={item.name + "-3742893"}
                                onChange={async (e) => {
                                    await statusService.checkItemInManifest({
                                        requesterId: delivery.requester_id,
                                        deliveryId: delivery.id,
                                        index,
                                        value: e.target.checked,
                                    });
                                }}
                                className="check-container"
                            >
                                {item.name} x{item.quantity}
                                <input
                                    htmlFor={item.name + "-3742893"}
                                    type="checkbox"
                                    defaultChecked={item.checked}
                                />
                                <span className="checkmark" />
                            </label>
                        ))}
                    </>
                ) : (
                    <span style={{ fontWeight: "bold" }}>No items</span>
                )}
            </div>
        );
    };

    const ModalGroupOptions = () => {
        return (
            <div className="modal-buttons">
                <button
                    className="button-cancel"
                    onClick={() => MySwal.clickCancel()}
                >
                    Cancel
                </button>
                <button
                    className="button-load"
                    onClick={() => MySwal.clickConfirm()}
                >
                    Yes
                </button>
                <button
                    className="button-close"
                    onClick={() => MySwal.clickDeny()}
                >
                    No
                </button>
            </div>
        );
    };

    const ModalGroupButtons = () => {
        return (
            <div className="modal-buttons">
                {/* <button className='button-cancel' onClick={() => handleCancelOrder()}>
                  Cancel order
                </button> */}
                <button
                    className="button-load"
                    onClick={() => handleOpenDoor()}
                >
                    Load order
                </button>
                <button
                    className="button-close"
                    onClick={() => handleCloseDoor()}
                >
                    Close Door
                </button>
            </div>
        );
    };

    const handlePrincipalContent = () => {
        MySwal.fire({
            title: <p className="dialog-title">{dialogTitle}</p>,
            showConfirmButton: false,
            html: <ModalContainer />,
            footer: <ModalGroupButtons />,
        }).then((result) => {});
    };

    return (
        <p onClick={handlePrincipalContent} className="dialog-action-text">
            {buttonText}
        </p>
    );
};

export default DialogActions;
