import React, { useState, useMemo, useContext, useEffect, useRef } from "react";

import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faListAlt,
    faHistory,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

// Pusher
import PusherHandler from "../utils/pusher";

// Sounds
import soundAlert from "../assets/sounds/soundalert.mp3";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// APP CONTEXT
import AppContext from "../utils/context/AppContext";

// SCSS
import "./_ordersPages.scss";
import DialogActions from "../components/DialogActions/DialogActions";

const atheneaLmmUrl = process.env.REACT_APP_ATHENEA_LMM;

const ActiveOrders = () => {
    const MySwal = withReactContent(Swal);
    const { state } = useContext(AppContext);
    const { user } = state;
    const audioElement = useRef(null);

    const fetchActiveDeliveries = async () => {
        const res = await axios.post(
            `${atheneaLmmUrl}/deliveries/search/requesters?activity=open`,
            {
                requester_ids: user?.metadataTwo?.requester_id || [],
            }
        );
        return res.data.data;
    };

    useEffect(() => {
        if (user) {
            const pusherHandler = new PusherHandler(
                user?.metadataTwo?.requester_id ?? [
                    "128e4379-98d0-4235-b5d9-2481f2bbe9f5",
                ]
            );
            pusherHandler.bindEvent("status_update", (data) => {
                switch (data.message.status) {
                    case "pending":
                        MySwal.fire({
                            title: "New Order",
                            icon: "info",
                            showConfirmButton: true,
                            showCancelButton: false,
                            allowEnterKey: false,
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            confirmButtonText: "OK",
                            confirmButtonColor: "#0082F5",
                            didDestroy: () => {
                                audioElement.current.pause();
                            },
                            didClose: () => {
                                audioElement.current.pause();
                            },
                            didOpen: () => {
                                audioElement.current.play();
                            },
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                audioElement.current.pause();
                            }
                        });
                        break;

                    case "at_pickup":
                        break;

                    default:
                        break;
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const { status, data } = useQuery(
        "activeDeliveries",
        fetchActiveDeliveries,
        {
            refetchInterval: 1000,
            refetchOnWindowFocus: true,
            staleTime: 1000,
            cacheTime: 0,
            keepPreviousData: false,
            enabled: user ? true : false,
        }
    );

    const [externalIdAndClientNameFilter, setExternalIdAndClientNameFilter] =
        useState("");
    const [statusFilter, setStatusFilter] = useState("");

    const activeDeliveries = useMemo(() => {
        if (!data) return [];
        if (externalIdAndClientNameFilter.length > 0) {
            return data.filter(
                (delivery) =>
                    delivery.dropoff.name
                        .toLowerCase()
                        .includes(
                            externalIdAndClientNameFilter.toLowerCase()
                        ) ||
                    delivery.external_id
                        .toLowerCase()
                        .includes(externalIdAndClientNameFilter.toLowerCase())
            );
        }
        if (statusFilter.length > 0) {
            return data.deliveries.filter((delivery) =>
                delivery.status
                    .toLowerCase()
                    .includes(statusFilter.toLowerCase())
            );
        }
        return data ?? [];
    }, [data, externalIdAndClientNameFilter, statusFilter]);

    const rowColor = (delivery) => {
        switch (delivery.status) {
            case "at_pickup":
                return "rgba(30, 199, 0, 0.3)";

            default:
                return "transparent";
        }
    };

    return (
        <>
            <div>
                <audio preload="true" loop ref={audioElement}>
                    <source src={soundAlert} type="audio/mp3" />
                    Your browser does not support the audio tag.
                </audio>
            </div>
            <h2 className="typeorders_title">Active Orders</h2>
            <div className="orders_container">
                <div className="group-nav-top">
                    <nav className="orders_container__nav">
                        <ul>
                            <li>
                                <Link className="active" to="/">
                                    <FontAwesomeIcon
                                        style={{ marginRight: "3px" }}
                                        icon={faListAlt}
                                        size="1x"
                                        fixedWidth
                                    />
                                    Active Orders
                                </Link>
                            </li>
                            <li>
                                <Link to="/history">
                                    <FontAwesomeIcon
                                        style={{ marginRight: "3px" }}
                                        icon={faHistory}
                                        size="1x"
                                        fixedWidth
                                    />
                                    Completed Orders
                                </Link>
                            </li>
                            <li>
                                <Link to="/canceled">
                                    <FontAwesomeIcon
                                        style={{ marginRight: "3px" }}
                                        icon={faTimesCircle}
                                        size="1x"
                                        fixedWidth
                                    />
                                    Canceled Orders
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <form
                        className="orders_container__filters"
                        onSubmit={(e) => e.preventDefault()}
                        noValidate
                    >
                        <input
                            className="filter-input"
                            type="text"
                            id="filter-externalid-clientname"
                            placeholder="Filter by external id or client id"
                            onChange={(e) => {
                                setExternalIdAndClientNameFilter(
                                    e.target.value
                                );
                            }}
                        />
                        <input
                            className="filter-input"
                            type="text"
                            id="filter-status"
                            placeholder="Filter by status"
                            onChange={(e) => {
                                setStatusFilter(e.target.value);
                            }}
                        />
                    </form>
                </div>
                <table className="orders_container__table">
                    <thead>
                        <tr className="table-header">
                            <th>ID</th>
                            <th>Location Name</th>
                            <th>Estimated at pickup</th>
                            <th>Estimated delivery time</th>
                            <th>Client name</th>
                            <th>Kiwibot</th>
                            <th>Status</th>
                            <th>Details Order</th>
                        </tr>
                    </thead>
                    <tbody>
                        {status === "success" && (
                            <>
                                {activeDeliveries.map((delivery) => (
                                    <tr
                                        className="table-content"
                                        style={{
                                            backgroundColor: rowColor(delivery),
                                        }}
                                        key={delivery.id}
                                    >
                                        <td>{delivery.external_id ?? "N/A"}</td>
                                        <td>
                                            {delivery.pickup.location_name ??
                                                "N/A"}
                                        </td>
                                        <td>
                                            {new Date(
                                                delivery.estimated_pickup_at
                                            ).toLocaleString()}
                                        </td>
                                        <td>
                                            {new Date(
                                                delivery.estimated_delivery_at
                                            ).toLocaleString()}
                                        </td>
                                        <td>
                                            {delivery.dropoff.name ?? "N/A"}
                                        </td>
                                        <td>
                                            {delivery.worker_id
                                                ? delivery.worker_id
                                                : "No available yet"}
                                        </td>
                                        <td>{delivery.status}</td>
                                        <td>
                                            <DialogActions
                                                dialogTitle="Details Order"
                                                buttonText="View details"
                                                delivery={delivery}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ActiveOrders;
