import { useState } from 'react';
import initialState from '../initialState';

/**
 * useInitialState - Global variables initializer
 *
 * Return:
 *        {Object}      state           - App store
 *
 */
const useInitialState = () => {
  const [state, setState] = useState(initialState.user);
  const [requesterId, setRequesterId] = useState(initialState.requesterId);

  const updateUser = (payload) => {
    setState({
      ...state,
      user: payload,
    });
  };

  const updateRequesterId = (payload) => {
    setRequesterId(payload);
  };

  return {
    state,
    requesterId,
    updateUser,
    updateRequesterId,
  };
};

export default useInitialState;
