import axios from 'axios';

const zeusUrl = process.env.REACT_APP_ZEUS_URL;
const atheneaUrl = process.env.REACT_APP_ATHENEA_URL;

const openDoor = async ({ requesterId, deliveryId }) => {
  try {
    const { data } = await axios.post(
      `${atheneaUrl}/v1/requesters/${requesterId}/deliveries/${deliveryId}/door?key=${process.env.REACT_APP_ATHENEA_API_KEY}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

const closeDoor = async ({ requesterId, deliveryId }) => {
  try {
    const { data } = await axios.post(
      `${atheneaUrl}/v1/requesters/${requesterId}/deliveries/${deliveryId}/door?key=${process.env.REACT_APP_ATHENEA_API_KEY}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

const cancelOrder = async ({ requesterId, deliveryId }) => {
  try {
    const { data } = await axios.post(
      `${atheneaUrl}/v1/requesters/${requesterId}/deliveries/${deliveryId}/cancel?key=${process.env.REACT_APP_ATHENEA_API_KEY}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

const markAsLoaded = async ({ zoneId, deliveryId }) => {
  try {
    const { data } = await axios.post(
      `${zeusUrl}/v1/tracker/zones/${zoneId}/deliveries/${deliveryId}/load?key=${process.env.REACT_APP_ZEUS_API_KEY}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

const sendNpsStore = async ({
  deliveryId,
  requesterId,
  satisfaction,
  botExperience,
  futureParticipation,
}) => {
  try {
    const { data } = await axios.post(
      `${atheneaUrl}/v1/requesters/${requesterId}/deliveries/${deliveryId}/nps?key=${process.env.REACT_APP_ATHENEA_API_KEY}`,
      {
        nps_type: 'store',
        satisfaction,
        bot_experience: botExperience,
        future_participation: futureParticipation,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

const checkItemInManifest = async ({
  deliveryId,
  requesterId,
  index,
  value,
}) => {
  try {
    const { data } = await axios.put(
      `${atheneaUrl}/v1/delivery/${requesterId}/${deliveryId}?key=${process.env.REACT_APP_ATHENEA_API_KEY}`,
      {
        items: {
          [index]: value,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  openDoor,
  closeDoor,
  cancelOrder,
  sendNpsStore,
  markAsLoaded,
  checkItemInManifest,
};
