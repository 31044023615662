import React from 'react';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import './_DialogSimple.scss';

const DialogSimple = ({
  buttonText = 'Button Modal',
  dialogTitle = 'Title',
  delivery,
}) => {
  const MySwal = withReactContent(Swal);

  const ModalContainer = () => {
    return (
      <div className='modal-container'>
        <div className='description'>
          <span className='description__title'>Description:</span>
          <p className='description__text'>
            {delivery.manifest.description ?? 'N/A'}
          </p>
        </div>

        {delivery.manifest.items.length > 0 ? (
          <>
            {delivery.manifest.items.map((item) => (
              <li key={item.name + '-3742893'}>
                {item.name} x{item.quantity}
              </li>
            ))}
          </>
        ) : (
          <span style={{ fontWeight: 'bold' }}>No items</span>
        )}
      </div>
    );
  };

  const handlePrincipalContent = () => {
    MySwal.fire({
      title: <p className='dialog-title'>{dialogTitle}</p>,
      showConfirmButton: false,
      html: <ModalContainer />,
    }).then((result) => {});
  };

  return (
    <p onClick={handlePrincipalContent} className='dialog-action-text'>
      {buttonText}
    </p>
  );
};

export default DialogSimple;
