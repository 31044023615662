const Pusher = require("pusher-js");

let instance = null;

class PusherHandler {
    _connectPusher(channels) {
        const socket = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
            cluster: process.env.REACT_APP_PUSHER_CLUSTER,
            encrypted: true,
        });

        this.channel = channels.map((channelName) =>
            socket.subscribe(channelName)
        );
        // this.channel = socket.subscribe(channel);
        socket.connection.bind("connected", () =>
            // eslint-disable-next-line no-console
            console.log("Connected to pusher")
        );
        socket.connection.bind("error", (err) =>
            // eslint-disable-next-line no-console
            console.log("Can't connect to pusher", err)
        );
    }

    bindEvent(event, callback) {
        for (let i = 0; i < this.channel.length; i++) {
            this.channel[i].bind(event, callback);
        }
    }

    constructor(channel) {
        if (!instance) {
            instance = this;
            this._connectPusher(channel);
        }
        return instance;
    }
}

export default PusherHandler;
